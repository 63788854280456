.modal-file-upload {
	min-width: min(400px, 100%);
}

.modal-file-upload .dzu-dropzone {
	overflow: hidden;
	border: none;
}

.modal-file-upload .dzu-previewFileName {
	color: inherit;
}

.modal-file-upload .dzu-previewButton {
	background: #EEE;
    border-radius: 10px;
}
							 
.chonky-navbarWrapper {
	background: rgba(0,0,0,0.1);
    padding: 0px;
    margin: -9px -9px 0px -9px;
    padding: 9px;
    border-bottom: 1px solid rgba(128,128,128,0.35);
}

.chonky-toolbarWrapper {
	background: rgba(0,0,0,0.1);
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(128,128,128,0.35);
    margin-left: -9px;
    margin-right: -9px;
	margin-top: -9px;
    padding: 9px 9px 0px 9px;
}

.chonky-chonkyRoot {
	overflow: hidden;
}

.chonky-fileListWrapper {
    padding-top: 10px;
}

.modal-file-upload .dzu-inputLabel {
    text-transform: uppercase;
    font-weight: normal;
    border: 1px dashed;
}

body .chonky-fileListWrapper {
	flex-grow: 1;
}

.dzu-dropzone .dzu-inputLabel {
	text-align: center;
	padding: 5px 60px;
}