.modal-form {
    text-align: center;
}

.theme-dark .modal-form label {
	color: #fff;
}

.modal-form .Mui-error.MuiFormHelperText-root {
    max-width: fit-content;
}

.modal-form .message {
    padding: 0 0 1.2rem 0;
    font-size: 1.1rem;
    text-align: left;
}

.modal-form > div > button {
    /*margin-top: 0.5rem;*/
    /* min-width: 250px; */
}

.file-picker-field, .color-picker-field {
    width: 100%;
    padding: 10px 14px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.color-picker-field .color-picker {
    flex-grow: 1;
    flex-shrink: 0;
}

.MuiInputBase-root {
    margin-bottom: 1rem;
    text-align: left;
}

.MuiInputBase-root + .MuiFormHelperText-root {
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.modal-form textarea {
    min-height: 70px;
    height: auto;
}

.modal-form .field-container > label {
    color: #000;
    display: block;
    text-align: left;
    text-transform: capitalize;
    margin-left: 14px;
    margin-bottom: .3rem;
}

.field-container {
    display: flex;
    flex-wrap: wrap;
}

.field-container > .MuiTextField-root, .field-container > .tagSelect {
    flex-grow: 1;
}