.mapWrapperPar
{
	display: inline-table;
	width: 100%;
	overflow: hidden;
}

.mapWrapper
{
	/*display: inline-block;*/
	display: table-cell;
    position: relative;
    width: 100%;
	height: fit-content;
	height: -moz-fit-content;
	overflow: hidden;
	vertical-align: middle;
	text-align: center;
}

#map-hover-canvas,
#map-background-mask,
#map-background {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: -9;
}
#map-background-mask {
	display: none;
}


.mapWrapperSub
{
	position: relative;
}

.kioskDot {
	position: absolute;	
	pointer-events: none;
}

.kioskDot:before {
	position: relative;
	content: "";
	width: 12px;
	height: 12px;
	background: magenta;
	border-radius: 12px;
	display: inline-block;
	top: -6px;
	right: -6px;
	border: 1px solid rgba(255,255,255,0.35);
}

.mapCursor {
	position: absolute;
	z-index: 9999;	
}

.mapCursor:before {
	content: "";
	position: relative;
	display: inline-block;
	width: 12px;	
	height: 12px;
	top: -6px;
	right: -6px;
	border: 1px solid #1b1d40;
	box-shadow: 0px 0px 5px white, 0px 0px 5px white;
	animation-name: pulseMapCursor;
	animation-duration: 2.0s;
	animation-timing-function: ease-out;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-play-state: running;
}

@keyframes pulseMapCursor {
	0% {
		transform: scale(1.4);
	}

	50% {
		transform: scale(1.65);
	}

	100% {
		transform: scale(1.4);
	}
}

.mapWrapperPar * {
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, left 0.5s ease-in-out, right 0.5s ease-in-out, width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.theme.fullscreen .mapWrapperPar, .theme.fullscreen .mapWrapper, .theme.fullscreen .mapWrapperSub {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: inline-block;
	height: 100%;
}

.theme.fullscreen .dashboard-role-wrapper > .MuiGrid-container > .MuiGrid-item:nth-child(1),
.theme.fullscreen .sidebar-areas,
.theme.fullscreen .tree.mode-kiosks {
	display: none;
}