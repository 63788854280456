.chonky-chonkyRoot {
    min-height: calc(100vh - 330px);
    flex-grow: 1;
}

.modal-window-file-explorer .chonky-chonkyRoot {
    min-height: unset;
}

.file-explorer-dialog {
    min-width: min(800px, 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.file-explorer-dialog > span {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.file-explorer-dialog > .dialog-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.file-explorer-dialog > .dialog-buttons > button {
    margin: 0;
    flex-grow: 1;
}

.statusbar-wrapper .statusBar {
    margin-bottom: -10px;
    margin-top: 10px;
    font-size: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.statusbar-wrapper .statusBar .permissions {
    display: flex;
    margin-right: 10px;
    border-right: 1px solid rgba(128,128,128,0.35);
    padding-right: 10px;
}

.statusbar-wrapper .statusBar .owner {
    font-size: 0.85em;
    display: flex;
}

.statusbar-wrapper .statusBar svg {
    font-size: 1.25em;
    margin: 0px 2px;
    cursor: help;
}

.statusbar-wrapper .perm-ico {
    position: relative;
    display: flex;
}

.statusbar-wrapper .perm-ico.disabled svg {
    opacity: 0.5;
}

.statusbar-wrapper .perm-ico.unknown svg {
    opacity: 0.1;
}

.statusbar-wrapper .perm-ico.disabled:after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 4px;
    background-color: #a00;
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 12px);
    transform: rotate(45deg);
    border: 1px solid #e5e5e5;
    background-clip: content-box;
}

.theme-dark .statusbar-wrapper .perm-ico.disabled:after {
    border: 1px solid #3b3b3b;
}

.statusbar-wrapper .sharing {
    margin-left: auto;
    font-size: 0.85em;
    display: flex;
}

.statusbar-wrapper span.user-strip {
    margin: 0px 5px 0px 10px;
}

.chonky-chonkyRoot {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.statusbar-wrapper {
    margin: -11px 9px 11px;
    height: 38px;
}