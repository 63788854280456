.file-editor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.file-editor-area {
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.editor {
  counter-reset: line;
}

.file-editor-area {
	border: 1px solid #ced4da;
	margin-bottom: 10px;
	border-radius: 5px;
}

.file-editor-buttons {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  flex-grow: 0;
  height: unset;
}

@media (max-width: 600px) {
  .file-editor-buttons button {
    flex-grow: 1;
  }
}

.chonky-navbarCopyinfo {
	margin: 10px 0px;
	border-radius: 5px;
	background: #FF05;
}

.chonky-navbarCopyinfo .chonky-toolbarLeft,
.chonky-navbarCopyinfo .chonky-toolbarRight {
	padding: 5px 10px;
}

.chonky-navbarCopyinfo .chonky-toolbarLeft {
	padding-top: 10px
}

.editor #codeArea {
  outline: none;
  padding-left: 60px !important;
}

.editor pre {
  padding-left: 60px !important;
}

.editor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #cccccc;
  text-align: right;
  width: 40px;
  font-weight: 100;
}

.editor span.token.operator {
    background: none;
}

.file-editor-title {
    background: #8883;
    padding: 5px 10px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
	line-height: 1;
}

.file-editor-title span {
	position: relative;
    top: -5px;
}

.file-explorer-dialog>.chonky-chonkyRoot>.MuiPopover-root {
    transform: translate(calc(-50vw + 430px), calc(-50vh + 340px));
}

body .chonky-toolbarLeft .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: rgba(128,128,128,0.5);
	border-width : 1px;
}

body .chonky-toolbarLeft .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: rgba(128,128,128,0.5);
	border-width : 1px;
}

body .theme-dark .chonky-toolbarLeft .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
body .theme-dark .chonky-toolbarLeft .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: rgba(255,255,255,0.5);
}

body .theme-light .chonky-toolbarLeft .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
body .theme-light .chonky-toolbarLeft .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: rgba(0,0,0,0.5);
}