.version p,
.version svg {
    display: inline;
    vertical-align: middle;
}

.version svg {
    margin-left: 5px;
}

.kiosk-info-card {
    /* Properties:
        - background-color,
        - box-shadow,
        - border-color,
        are defined directly in KioskDetail using MUI theme.
    */
    height: 100%;
    min-height: 155px;
    position: relative;
    border-style: none solid solid;
    border-width: 1px;
    text-align: left;
    padding: 15px 10px 10px;
    /* font-family: "Rawline Regular", sans-serif; */
}

.kiosk-info-card::before {
    content: "";
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    margin-left: -1px;
    position: absolute;
    height: 7px;
    background: linear-gradient(to left, #d92a55 50%, #202241 50%);
}

.kiosk-info-card .attributes {
    width: 100%;
}

.kiosk-info-card .attributes span {
    width: 100%;
    font-size: 0.875rem;
    display: inline-flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
}

.kiosk-card-icon {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 90px !important;
    height: 90px !important;
    /* Properties:
        - color,
        are defined directly in KioskDetail using MUI theme.
    */
    opacity: 0.075;
    z-index: 0;
}

.kiosk-info-card h4 {
    margin: 0 0 7px;
    /* Properties:
        - color,
        are defined directly in KioskDetail using MUI theme.
    */
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.125rem;
    /* font-family: "Rawline Semibold"; */
    grid-column: 1 / 3;
}

.kiosk-info-card p {
    margin: 0;
    padding: 0;
}

.kiosk-info-card p:nth-of-type(2n + 1) {
    font-weight: bold;
    font-family: "Rawline Medium", sans-serif;
    text-align: left;
}

.kiosk-info-card p:nth-of-type(2n) {
    font-family: "Rawline Regular", sans-serif;
    text-align: right;
}

.kiosk-info-card .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 15px;
}

.kiosk-info-card .additional-content {
    max-width: 100px;
    display: inline-block;
}

.kiosk-info-card .additional-content + .attributes {
    width: calc(100% - 100px);
}

.kiosk-option-buttons .MuiButton-root {
    width: 100%;
    height: 100%;
}

.local-side-menu div, .local-side-menu .MuiButton-root {
	width: 100%;
}

.kiosk-screenshot-wrapper {
	width: 100%;
	height: 100%;
}

.kiosk-screenshot-wrapper {
	position: relative;
}

.kiosk-screenshot-wrapper img {
	max-width: 100%;
    max-height: 100%;
}

.kiosk-screenshot-wrapper div {
    width: 100%;
    height: 100%;
}

.kiosk-info-card {
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
}

.kioskInfo .activity-true {
    color: #00B0A6;
}

.kioskInfo .activity-false {
    color: #d92a55;
}

/* Support for multi-size kiosk screenshot: */

.kiosk-detail-main-grid {
    justify-content: center;
}

.kiosk-detail-main-grid > div:nth-child(1) {
    flex-basis: auto;
    padding: 16px 16px 0px 16px;
    max-width: 100%;
    text-align: center;
}

@media (max-width: 899px) {
    .kiosk-detail-main-grid > div:nth-child(1) {
        flex-grow: 1;
    }
}
.kiosk-detail-main-grid > div:nth-child(2) {
    flex-basis: 40%;
    max-width: 100%;
    flex-grow: 1;
}

.kiosk-screenshot-wrapper {
    display: inline-block;
    justify-content: center;
    min-width: 200px; /* Might be changed by rebranding */
}

.kiosk-screenshot-wrapper .layer-back-1 {
    margin: 0px 20px;
}

.micro-ico-button svg {
    height: 16px;
    width: 16px;
    line-height: 21px;
}

.micro-ico-button {
    width: 26px;
}

.micro-ico-button.shift-up {
    position: relative;
    top: -3.5px;
    margin: -5px -5px -5px 0px;
}

.kiosk-orientation-preview {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    zoom: 2;
    margin-left: 15px;
    transition: transform ease-in-out 0.5s;
}

.kiosk-orientation-preview.orientation-normal {
    transform: rotate(0deg);
}

.kiosk-orientation-preview.orientation-inverted {
    transform: rotate(180deg);
}

.kiosk-orientation-preview.orientation-left {
    transform: rotate(-90deg);
}

.kiosk-orientation-preview.orientation-right {
    transform: rotate(90deg);
}

span.wifi-signal-icon {
    float: right;
}

.wifi-more {
    margin-top: -52px !important;
    margin-right: 8px !important;
}

.wifi-record > div.MuiCardContent-root {
    padding-bottom: 16px;
}
.wifi-record .MuiCardActions-root {
    padding: 0px;
}