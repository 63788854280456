.crud-pagination {
    margin: 12px 0;
    float: right;
}

.crud-table .crud-button,
.crud-button.MuiButton-root {
    margin: 10px 0px;
}

.crud-table tr td:last-of-type .crud-button:not(:last-child) {
    /*margin-right: 5px;*/
}

.crud-button {
    min-height: 1rem;
    border-radius: 3px;
    padding: 0.6rem 1rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

.crud-table {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    overflow: auto;
}

.theme-dark .crud-table thead {
    /* Properties:
    - background-color,
    - opacity
    are defined directly in CRUDTable using MUI theme.
    */
}

.theme-light .crud-table thead {
    background-color: unset;
    opacity: 0.8;
}

.crud-table tr {
    /* Properties:
    - border-color,
    are defined directly in CRUDTable using MUI theme.
    */
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.crud-table th, .crud-table td {
    padding: 0.5rem 1rem;

    border-left-style: solid;
    border-left-width: 1px;
    /* Properties:
    - border-color,
    are defined directly in CRUDTable using MUI theme.
    */

    font-size: 16px;
}

.crud-table th:first-of-type, .crud-table td:first-of-type {
    border-left-style: none;
}

.crud-table th {
    opacity: 1;
    text-align: center;
    font-weight: 800 !important;
}

.crud-table th.sortable {
    cursor: pointer;
}

/* TODO: define using MUI theme */
.crud-table .even-odd > tr:nth-of-type(2n+1) {
    background-color: rgba(0, 0, 0, 0.08);
}

.crud-table .even-odd > tr:nth-of-type(2n) {
    background-color: transparent;
}

/* TODO: define using MUI theme */
.inner-table td {
    border-color: rgba(0, 0, 0, 0.09);
    padding-top: 4px;
    padding-bottom: 4px;
}

.inner-table td:first-of-type {
    padding-left: 0;
    opacity: 0.5;
}

.inner-table td:last-of-type {
    padding-right: 0;
}

.inner-table tr:last-of-type,
.inner-table tr:last-of-type td {
    border-bottom: none;
}

@media screen and (max-width: 600px) {
    .crud-table {
        margin-top: 1rem;
        overflow: hidden;
        margin-bottom: 2rem;
    }

    .crud-table tr,
    .crud-table thead,
    .crud-table tbody,
    .crud-table td {
        display: block;
        width: 100%;
    }

    .crud-table thead,
    .crud-table th {
        display: none;
    }

    .crud-table td {
        border-bottom: 0;
        border-left: 0;
    }

    .crud-table td:last-child {
        padding-bottom: 2rem;
    }

    .crud-table tr:not(:last-child) {
        margin-bottom: 2rem;
    }
}