.entitySearch .entityInputField {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 0.7rem;
}

.entitySearch .selected {
  width: 250px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
  margin-top: 0.3rem;
}

.entitySearch .suggestions {
  position: relative;
  margin-top: -1rem;
  top: 100%;
  left: 0;
  /* Properties:
    - background-color,
    - box-shadow,
    are defined directly in EntitySearch using MUI theme.
  */
}

.crud-modal-form .entitySearch .suggestions {
  margin-top: -1.5rem;
}

.entitySearch .suggestion {
  width: 100%;
  margin: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  /* Properties:
    - border-color,
    are defined directly in EntitySearch using MUI theme.
  */
  text-align: left;
}

.entitySearch .suggestion mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
  /* Properties:
    - color,
    are defined directly in EntitySearch using MUI theme.
  */
}